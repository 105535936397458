'use client'

import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { IconArrowMoveAi } from 'icons/components/IconArrowMoveAi'
import { IconBackspace } from 'icons/components/IconBackspace'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import { useCarousel } from '../../context/CarouselProvider'
import { useCarouselMove } from '../../context/useCarouselMove/useCarouselMove'
import type { CarouselDirection } from '../../types'

import styles from './Arrows.module.scss'

const SIZE = 16

type ArrowProps = {
	readonly direction: CarouselDirection
}

export function Arrow({ direction }: ArrowProps) {
	const {
		country: { isRtl },
	} = useMasterData()
	const { moveTo } = useCarouselMove()
	const { isAt } = useCarousel()
	const isForward = direction === 'FORWARD'
	const isDisabled = (isForward && isAt.end) || (!isForward && isAt.start)

	return (
		<ButtonIconTransparent
			disabled={isDisabled}
			onClick={() => moveTo(direction)}
			className={styles.arrow}
			lang='en'
			aria-label='Scroll carousel' // TODO translate
		>
			{isForward ? (
				<IconArrowMoveAi width={SIZE} height={SIZE} rotateRtl={isRtl} />
			) : (
				<IconBackspace width={SIZE} height={SIZE} rotateRtl={isRtl} />
			)}
		</ButtonIconTransparent>
	)
}
